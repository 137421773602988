import React from "react"
import { projectCard, cardText, cardPicture } from "./project-card.module.css"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const ProjectCard = ({ projectTitle, description }) => {
    return (
        <li className={projectCard}>
            <Link to="/" className={cardPicture}>
                <StaticImage
                    src="../static/images/favicon.png"
                    alt="Peter Mason Profile"
                    placeholder="tracedSVG" />
            </Link>
            <div className={cardText}>
                <h2>{projectTitle}</h2>
                {description}
            </div>
        </li>
        //TODO - make project name and image into GitHub links

    )
}

export default ProjectCard