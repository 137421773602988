import * as React from "react"
import Layout from "../components/layout"
import "../styles/projects.css"
import ProjectCard from "../components/project-card"
import "../styles/normalize.css"

const ProjectPage = () => {
    return (
        <main>
            <Layout pageTitle="Peter Mason | Projects" opacity='low'>
                <div class="no-scroll">
                    <h1 class="project-page-title">My Projects</h1>
                </div>
                <div class="scroll">
                    <ProjectCard projectTitle={"NumPy"} imageSource="../static/images/favicon.png" description="NumPy is an essential component in the burgeoning Python visualization landscape, which includes Matplotlib, Seaborn, Plotly, Altair, Bokeh, Holoviz, Vispy, Napari, and PyVista, to name a few. NumPy's accelerated processing of large arrays allows researchers to visualize datasets far larger than native Python could handle." />
                    <ProjectCard projectTitle={"Project Title"} imageSource="../static/images/favicon.png" description="Lorem ipsum" />
                    <ProjectCard projectTitle={"Project Title"} imageSource="../static/images/favicon.png" description="Lorem ipsum" />
                    <ProjectCard projectTitle={"Project Title"} imageSource="../static/images/favicon.png" description="Lorem ipsum" />
                    <ProjectCard projectTitle={"Project Title"} imageSource="../static/images/favicon.png" description="Lorem ipsum" />
                    <ProjectCard projectTitle={"Project Title"} imageSource="../static/images/favicon.png" description="Lorem ipsum" />
                    <ProjectCard projectTitle={"Project Title"} imageSource="../static/images/favicon.png" description="Lorem ipsum" />
                    <ProjectCard projectTitle={"Project Title"} imageSource="../static/images/favicon.png" description="Lorem ipsum" />
                    <ProjectCard projectTitle={"Project Title"} imageSource="../static/images/favicon.png" description="Lorem ipsum" />
                    <ProjectCard projectTitle={"Project Title"} imageSource="../static/images/favicon.png" description="Lorem ipsum" />
                    <ProjectCard projectTitle={"Project Title"} imageSource="../static/images/favicon.png" description="Lorem ipsum" />
                </div>


            </Layout>

        </main>
    )
}

export default ProjectPage